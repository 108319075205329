import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "@/assets/css/custom.css"
import "@/assets/css/colors.css"
import "@/assets/css/palette-gradient.css"
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import './registerServiceWorker'
import AddToHomescreen from '@owliehq/vue-addtohomescreen';
import process from "process";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

createApp(App)
    .component('EasyDataTable', Vue3EasyDataTable)
    .use(VueSweetalert2)
    .use(store)
    .use(router)
    .use(AddToHomescreen, {
        buttonColor: '#0097A7',
        title: process.env.VUE_APP_APP_NAME,
        content: 'Aggiungi l\'app alla home',
        iconPath: '/img/icons/android-chrome-512x512.png',
    })
    .mount('#app');

let tokenData = localStorage.getItem('tokenData');
if (tokenData) {
    store.commit('user/SET_TOKEN', JSON.parse(atob(tokenData)))
}