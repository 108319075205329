import {create, getItem, getList, remove, update} from "@/api/instrument";

const state = {
    list: [],
}

const mutations = {
    SET_LIST(state, value) {
        state.list = value
    },
}

const actions = {
    list({commit}) {
        return new Promise((resolve, reject) => {
            getList().then(response => {
                try {

                    commit('SET_LIST', response.instruments)
                    resolve()
                } catch (e){
                    reject(e)
                }
            }).catch(error => {

                reject(error)
            })
        })
    },
    delete({commit}, item) {
        return new Promise((resolve, reject) => {
            remove(item).then(() => {
                try {
                    commit('SET_LIST', state.list.filter(e => e.id !== item.id))
                    resolve()
                } catch (e){
                    reject(e)
                }
            }).catch(error => {

                reject(error)
            })
        })
    },

    get({commit}, itemId) {
        return new Promise((resolve, reject) => {
            getItem(itemId).then((result) => {
                try {
                    let newList = state.list.filter(el => el.id !== result.instrument.id)
                    newList.push(result.instrument)
                    commit('SET_LIST', newList)
                    resolve()
                } catch (e){
                    reject(e)
                }
            }).catch(error => {

                reject(error)
            })
        })
    },

    create({commit}, item) {
        return new Promise((resolve, reject) => {
            create(item).then((result) => {
                try {
                    commit('SET_LIST', state.list.concat(result.instrument))
                    resolve()
                } catch (e){
                    reject(e)
                }
            }).catch(error => {

                reject(error)
            })
        })
    },

    update({commit}, item) {
        return new Promise((resolve, reject) => {
            update(item).then((result) => {
                try {
                    commit('SET_LIST', state.list.map(el => el.id === result.instrument.id ? result.instrument : el))
                    resolve()
                } catch (e) {
                    reject(e)
                }
            }).catch(error => {

                reject(error)
            })
        })
    },
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}