<script setup>
import {useStore} from "vuex";

const store = useStore();

defineProps({
  to: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
  navText: {
    type: String,
    required: true,
  },
});
</script>
<template>
  <li class="nav-item">
    <router-link :to="to" class="nav-link px-3" @click.stop.prevent="store.state.showSidenav = false">
      <span class="me-2">
        <i class="fa-solid" :class="icon"></i>
      </span>
      <span>{{
          navText
        }}</span>
    </router-link>
  </li>
</template>
