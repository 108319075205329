import {getCurrentUser, getList, login, remove, create, getItem, update} from '@/api/user'

const state = {
    token: null,
    user: null,
    list: [],
}
const mutations = {
    SET_USER(state, value) {
        state.user = value
    },
    SET_TOKEN(state, value) {
        state.token = value
    },
    SET_LIST(state, value) {
        state.list = value
    },
}

const actions = {
    login({commit}, userInfo) {
        const {email, password} = userInfo
        return new Promise((resolve, reject) => {
            login({email: email.trim(), password: password}).then(response => {

                const tokenData = (({role, token}) => ({role, token}))(response);
                localStorage.setItem( 'tokenData', btoa(JSON.stringify(tokenData)) );
                commit('SET_TOKEN', tokenData)
                //setToken(data.token)
                resolve()
            }).catch(error => {

                reject(error)
            })
        })
    },
    logout({commit}){
        //TODO: chiamata al backend per invalidare il token

        commit('SET_USER', null);
        commit('SET_TOKEN', null)
        commit('SET_LIST', [])
        localStorage.removeItem( 'tokenData' );
        return new Promise((resolve) => {resolve()});
    },
    current({commit}) {
        return new Promise((resolve, reject) => {
            getCurrentUser().then(response => {
                try {
                    commit('SET_USER', response.user)
                    resolve()
                } catch (e){
                    reject(e)
                }
            }).catch(error => {

                reject(error)
            })
        })
    },
    list({commit}) {
        return new Promise((resolve, reject) => {
            getList().then(response => {
                try {

                    commit('SET_LIST', response.users)
                    resolve()
                } catch (e){
                    reject(e)
                }
            }).catch(error => {

                reject(error)
            })
        })
    },
    create({commit}, item) {
        return new Promise((resolve, reject) => {
            create(item).then((result) => {
                try {
                    commit('SET_LIST', state.list.concat(result.user))
                    resolve()
                } catch (e){
                    reject(e)
                }
            }).catch(error => {

                reject(error)
            })
        })
    },
    update({commit}, item) {
        return new Promise((resolve, reject) => {
            update(item).then((result) => {
                try {
                    commit('SET_LIST', state.list.map(el => el.id === result.user.id ? result.user : el))
                    resolve()
                } catch (e) {
                    reject(e)
                }
            }).catch(error => {

                reject(error)
            })
        })
    },
    get({commit}, itemId) {
        return new Promise((resolve, reject) => {
            getItem(itemId).then((result) => {
                try {
                    let newList = state.list.filter(el => el.id !== result.user.id)
                    newList.push(result.user)
                    commit('SET_LIST', newList)
                    resolve()
                } catch (e){
                    reject(e)
                }
            }).catch(error => {

                reject(error)
            })
        })
    },
    delete({commit}, item) {
        return new Promise((resolve, reject) => {
            remove(item).then(() => {
                try {
                    commit('SET_LIST', state.list.filter(e => e.id !== item.id))
                    resolve()
                } catch (e){
                    reject(e)
                }
            }).catch(error => {

                reject(error)
            })
        })
    },

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}