<script setup>
import {useRoute, useRouter} from "vue-router";

import SidenavItem from "./SidenavItem.vue";
import store from "@/store";
import {computed} from "vue";

const route = useRoute();
const router = useRouter();
const token = computed(()=>store.getters.token);
const filteredRoutes = computed(()=>router.options.routes.filter((r) => r.meta?.icon && r.meta?.roles.includes(token.value?.role)));
</script>
<template>
  <ul class="navbar-nav">
    <sidenav-item
        v-for="(routeConfig, index) in filteredRoutes"
        :key="index"
        :to="routeConfig.path"
        :class="route.name === routeConfig.name ? 'active' : ''"
        :navText="routeConfig.meta.label"
        :icon="routeConfig.meta.icon"
    />
  </ul>
</template>
