<script setup>

import {availabilityStatus} from "@/utils/constants";
import store from "@/store";
import {inject, ref} from "vue";

defineProps({
  eventItem: {
    required: true,
  },
});
const swal = inject('$swal')
const edit = ref(false);
const loading = ref(false);


const updateAvailability = (eventItem, status) => {
  loading.value = true;
  store.dispatch('event/updateAvailability', {'item': eventItem, 'status': status})
      .then(() => {
        edit.value = false
        loading.value = false
      })
      .catch((response) => {
        loading.value = false
        swal.fire({
          icon: "error",
          title: "Oops...",
          text: response
        });
      });
};
</script>
<template>
  <div @click.stop.prevent="">
    <div class="flexbox gap-2">
      <template v-if="loading">
        <div class="cell d-grid">
          <button class="btn btn-primary disabled">
            <i class="fa-solid fa-spinner fa-spin-pulse"></i>
          </button>
        </div>
      </template>
      <template v-else-if="!eventItem.lock && (eventItem.user_availability == null || edit)">
        <div class="cell d-grid">
          <button class="btn btn-danger" type="button"
                  @click.stop.prevent="updateAvailability(eventItem, availabilityStatus.ABSENT)">
            <i class="fa-solid fa-thumbs-down"></i> Assente
          </button>
        </div>
        <div class="cell d-grid">
          <button class="btn btn-success"
                  @click.stop.prevent="updateAvailability(eventItem, availabilityStatus.PRESENT)">
            <i class="fa-solid fa-thumbs-up"></i> Presente
          </button>
        </div>
        <div class="cell d-grid">
          <button v-if="edit" class="btn btn-primary" @click.stop.prevent="edit = false">
            <i class="fa-solid fa-xmark"></i> Annulla
          </button>
        </div>
      </template>
      <template v-else-if="eventItem.user_availability === availabilityStatus.PRESENT">
        <div class="cell d-grid">
          <button class="btn btn-success disabled">
            <i class="fa-solid fa-thumbs-up"></i> Presente
          </button>
        </div>
        <div v-if="!eventItem.lock" class="cell d-grid">
          <button v-if="!eventItem.lock" class="btn btn-primary"
                  @click.stop.prevent="edit = true">
            <i class="fa-solid fa-user-pen"></i> Modifica
          </button>
        </div>
      </template>
      <template v-else>
        <div class="cell d-grid">
          <div class="btn btn-danger disabled">
            <i class="fa-solid fa-thumbs-down"></i> Assente
          </div>
        </div>
        <div v-if="!eventItem.lock" class="cell d-grid">
          <button v-if="!eventItem.lock" class="btn btn-primary"
                  @click.stop.prevent="edit = true">
            <i class="fa-solid fa-user-pen"></i> Modifica
          </button>
        </div>
      </template>
    </div>
  </div>
</template>