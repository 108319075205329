import request from '@/utils/request'
export function getList() {
    return request({
        url: '/api/v1/statistics',
        method: 'get'
    })
}
export function getFullList() {
    return request({
        url: '/api/v1/statistics/full',
        method: 'get'
    })
}