import request from '@/utils/request'
export function getList() {
    return request({
        url: '/api/v1/instrument',
        method: 'get'
    })
}

export function getItem(id) {
    return request({
        url: '/api/v1/instrument/' + id,
        method: 'get'
    })
}

export function create(item) {
    return request({
        url: '/api/v1/instrument/new',
        method: 'post',
        data: item
    })
}


export function update(item) {
    return request({
        url: '/api/v1/instrument/' + item.instrument.id,
        method: 'put',
        data: item
    })
}


export function remove(item) {
    return request({
        url: '/api/v1/instrument/' + item.id,
        method: 'delete'
    })
}