<script setup>
import Sidenav from "@/layout/Sidenav";
import Navbar from "@/layout/Navbars/Navbar.vue";
import AppFooter from "@/layout/Footer.vue";
import {useStore} from "vuex";
import {useRoute} from "vue-router";
import store from "@/store";

const route = useRoute();
useStore();
</script>
<template>
  <!-- top navigation bar -->
  <navbar v-if="route.name !== 'login'" @click.stop.prevent="store.state.showSidenav = false"/>
  <!-- top navigation bar -->

  <!-- offcanvas -->
  <sidenav/>
  <!-- offcanvas -->

  <div class="container-fluid" @click.stop.prevent="store.state.showSidenav = false">
    <div class="row">
      <main class="ml-sm-auto px-md-4 py-4">
        <router-view/>
        <app-footer/>
      </main>
    </div>
  </div>

  <div class="landing-bg h-100 bg-gradient-primary position-fixed w-100"></div>
</template>
