<script setup>
import StatCard from "@/components/home/StatCard.vue";
import {computed, onMounted} from "vue";
import store from "@/store";

const stats = computed(() => store.state.quickStats.stats)
const lastUpdate = computed(() => store.state.quickStats.lastUpdate)

onMounted(() => {
  if (lastUpdate.value === null || new Date().getTime() - new Date(lastUpdate.value).getTime() > (15 * 60 * 1000)) {
    store.dispatch('quickStats/get');
  }
})
</script>

<template>
  <div class="flexbox">
    <StatCard color="warning" icon="fa-music" label="Prove Fatte" :value=stats.past_rehearsal
              v-if="stats.past_rehearsal"/>
    <StatCard color="primary" icon="fa-calendar" label="Prossime Prove" :value=stats.next_rehearsal
              v-if="stats.next_rehearsal"/>
    <StatCard color="success" icon="fa-handshake-simple" label="Concerti Fatti" :value=stats.past_gig
              v-if="stats.past_gig"/>
    <StatCard color="purple" icon="fa-ticket-simple" label="Prossimi Concerti" :value=stats.next_gig
              v-if="stats.next_gig"/>
    <StatCard color="danger" icon="fa-eur" label="Da Avere"
              :value="(Math.round(stats.wait_refund * 100) / 100).toFixed(2)"
              v-if="stats.wait_refund !== undefined && stats.wait_refund !== null"/>
    <StatCard color="pink" icon="fa-wallet" label="Fondo Cassa"
              :value="(Math.round(stats.wallet_total * 100) / 100).toFixed(2)" v-if="stats.wallet_total"/>
  </div>
</template>