import request from '@/utils/request'
export function getList(data = null) {
    return request({
        url: '/api/v1/events?column=start_date',
        method: 'post',
        data
    })
}

export function getItem(id) {
    return request({
        url: '/api/v1/events/' + id,
        method: 'get'
    })
}

export function create(item) {
    return request({
        url: '/api/v1/events/new',
        method: 'post',
        data: item
    })
}

export function update(item) {
    return request({
        url: '/api/v1/events/' + item.event.id,
        method: 'put',
        data: item
    })
}


export function remove(item) {
    return request({
        url: '/api/v1/events/' + item.id,
        method: 'delete'
    })
}

export function updateAvailability(item, status) {
    return request({
        url: '/api/v1/events/' + item.id + '/status',
        method: 'put',
        data: status
    })
}

export function getAvailabilities(item) {
    return request({
        url: '/api/v1/events/' + item.id + '/availabilities',
        method: 'get'
    })
}

export function setAvailability(itemId, status) {
    return request({
        url: '/api/v1/events/' + itemId + '/availabilities',
        method: 'put',
        data: status,
    })
}