import Vuex from 'vuex'

const state = {
  showSidenav: false,
}
const getters = {
  showSidenav: state => state.showSidenav,
  token: state => state.user.token,
  isAdmin: state => state.user?.token?.role !== undefined && state.user.token.role === 'admin',
}
// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})


const store = new Vuex.Store({
  state,
  modules,
  getters
})

export default store