<script setup>
import {eventType, status} from "@/utils/constants";
import Status from "@/components/event/Status.vue";
import moment from "moment";

const ics = require('ics')

const props = defineProps(['event'])

const formatDate = (value) => new Date(value).toLocaleDateString();
const formatDateTime = (value) => new Date(value).toLocaleDateString('it', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit'
}).replace(',', ' alle ');

const getNameOfDay = (value) => new Date(value).toLocaleDateString('it', {weekday: 'long'});

const downloadIcs = () => {
  const startDate = moment(props.event.start_date + ' ' + props.event.start_time, 'YYYY-MM-DD HH:mm');

  const event = {
    start: [parseInt(startDate.format('YYYY')), parseInt(startDate.format('MM')), parseInt(startDate.format('DD')), parseInt(startDate.format('HH')), parseInt(startDate.format('mm'))],
    title: props.event.short_description,
    location: props.event.location,
    url: window.location.origin + '/#/events/' + props.event.id,
    geo: {lat: props.event.lat, lon: props.event.lon},
    status: 'CONFIRMED',
    busyStatus: 'BUSY',
    uid: 'band-planner-' + props.event.id
  }

  if (props.event.end_date) {
    const endDate = moment(props.event.end_date);
    event.end = [parseInt(endDate.format('YYYY')), parseInt(endDate.format('MM')), parseInt(endDate.format('DD')), parseInt(endDate.format('HH')), parseInt(endDate.format('mm'))];
  } else {
    event.duration = {hours: 4, minutes: 0};
  }

  ics.createEvent(event, (error, value) => {
    if (error) {
      console.log(error)
      return
    }

    console.log(value)
    window.open("data:text/calendar;charset=utf8," + encodeURIComponent(value));
  })
}
</script>

<template>
  <div class="cell px-2 mb-4">
    <div class="card">
      <router-link :to="'/events/'+event.id">
        <div class="text-center" style="background-color: aliceblue">
          <div class="card-body pb-0">
            <h4 class="card-title mb-0">
              <i class="fa-solid fa-lock fa-2xs" v-if="event.lock"></i>
              {{ event.status === status.CONFIRMED ? 'CONFERMATO' : 'FORSE' }} -
              {{ event.short_description }}
            </h4>
          </div>
          <div class="">
            <Status :eventItem=event class="card-body d-grid gap-2"/>
            <div @click.stop.prevent="" class="card-body d-grid">
              <div class="flexbox ">
                <div class="cell-full d-grid">
                  <div class="btn btn-blue-grey cell d-grid" type="button"
                       @click.stop.prevent="downloadIcs()">Aggiungi al calendario
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="list-group list-group-flush">
          <div class="list-group-item">
            <i class="fa-solid fa-play"></i>
            {{ event.type === eventType.GIG ? 'Concerto' : 'Prova' }}
          </div>
          <div class="list-group-item">
            <i class="fa-solid fa-map-location-dot"></i> {{ event.location }}
          </div>
          <div class="list-group-item">
            <i class="fa-solid fa-thumbs-up"></i> Presenti: {{ event.present }}
          </div>
          <div class="list-group-item">
            <i class="fa-solid fa-thumbs-down"></i> Assenti: {{ event.absent }}
          </div>
          <div class="list-group-item">
            <i class="fa-solid fa-calendar-day"></i> {{ formatDate(event.start_date) }}
            {{ (event.start_time) ? ' alle ' + event.start_time.slice(0, 5) : '' }}
            ({{
              getNameOfDay(event.start_date)
            }})
          </div>
          <div class="list-group-item" v-if="event.end_date">
            <i class="fa-solid fa-calendar-check"></i> {{ formatDateTime(event.end_date) }} ({{
              getNameOfDay(event.end_date)
            }})
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<style scoped>
.cell-full {
  flex-grow: 1;
}

.cell {
  flex-basis: 100%;
  flex-grow: 1;
}

@media screen and (min-width: 858px) {
  .cell {
    flex-basis: 50%;
    flex-grow: 0;
  }
}

@media screen and (min-width: 1200px) {
  .cell {
    flex-basis: 25%;
    flex-grow: 0;
  }
}
</style>