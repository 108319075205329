import request from '@/utils/request'

export function login(data) {
    return request({
        url: '/api/v1/login',
        method: 'post',
        data
    })
}

export function logout() {
    return request({
        url: '/api/v1/logout',
        method: 'post'
    })
}
export function getCurrentUser() {
    return request({
        url: '/api/v1/users/current',
        method: 'get'
    })
}
export function getList() {
    return request({
        url: '/api/v1/users',
        method: 'get'
    })
}

export function getItem(id) {
    return request({
        url: '/api/v1/users/' + id,
        method: 'get'
    })
}

export function create(item) {
    return request({
        url: '/api/v1/users/new',
        method: 'post',
        data: item
    })
}

export function update(item) {
    return request({
        url: '/api/v1/users/' + item.user.id,
        method: 'put',
        data: item
    })
}


export function remove(item) {
    return request({
        url: '/api/v1/users/' + item.id,
        method: 'delete'
    })
}