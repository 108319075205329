<script setup>
import {useStore} from "vuex";
import {computed} from "vue";

import SidenavList from "./SidenavList.vue";

const store = useStore();
const showSidenav = computed(() => store.state.showSidenav);
</script>
<template>
  <div
      class="offcanvas offcanvas-start sidebar-nav bg-dark"
      :class="(showSidenav)?'show':''"
      tabindex="-1"
      id="sidebar"
  >
    <div class="offcanvas-body p-0">
      <nav class="navbar-dark">
        <sidenav-list/>
      </nav>
    </div>
  </div>
</template>
