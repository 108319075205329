import {createRouter, createWebHashHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from "@/store";

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      'label': 'Home',
      'icon': 'fa-house',
      'roles': ['admin', 'user']
    },
    component: HomeView
  },
  {
    path: '/events',
    name: 'events',
    meta: {
      'label': 'Eventi',
      'icon': 'fa-calendar',
      'roles': ['admin', 'user']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "events" */ '../views/events/list.vue')
  },
  {
    path: '/events/:id(\\d+)',
    name: 'events.detail',
    meta: {
      'roles': ['admin', 'user']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "events" */ '../views/events/detail.vue')
  },
  {
    path: '/events/edit/:id(\\d+)',
    name: 'events.edit',
    meta: {
      'roles': ['admin']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "events" */ '../views/events/edit.vue')
  },
  {
    path: '/songs',
    name: 'songs',
    meta: {
      'label': 'Brani',
      'icon': 'fa-music',
      'roles': ['admin', 'user']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "songs" */ '../views/songs/list.vue')
  },
  {
    path: '/songs/:id(\\d+)/:instrument(\\d+)',
    name: 'songs.sheet',
    meta: {
      'roles': ['admin', 'user']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "songs" */ '../views/songs/sheet.vue')
  },
  {
    path: '/songs/edit/:id(\\d+)',
    name: 'songs.edit',
    meta: {
      'roles': ['admin']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "songs" */ '../views/songs/edit.vue')
  },
  {
    path: '/songs/new',
    name: 'songs.new',
    meta: {
      'roles': ['admin']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "songs" */ '../views/songs/new.vue')
  },
  {
    path: '/instruments',
    name: 'instruments',
    meta: {
      'label': 'Strumenti',
      'icon': 'fa-guitar',
      'roles': ['admin']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "instruments" */ '../views/instruments/list.vue')
  },
  {
    path: '/instruments/edit/:id(\\d+)',
    name: 'instruments.edit',
    meta: {
      'roles': ['admin']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "instruments" */ '../views/instruments/edit.vue')
  },
  {
    path: '/instruments/new',
    name: 'instruments.new',
    meta: {
      'roles': ['admin']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "instruments" */ '../views/instruments/new.vue')
  },
  {
    path: '/genres',
    name: 'genres',
    meta: {
      'label': 'Generi',
      'icon': 'fa-music',
      'roles': ['admin']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "genres" */ '../views/genres/list.vue')
  },
  {
    path: '/genres/edit/:id(\\d+)',
    name: 'genres.edit',
    meta: {
      'roles': ['admin']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "genres" */ '../views/genres/edit.vue')
  },
  {
    path: '/genres/new',
    name: 'genres.new',
    meta: {
      'roles': ['admin']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "genres" */ '../views/genres/new.vue')
  },
  {
    path: '/movements',
    name: 'movements',
    meta: {
      'label': 'Movimenti',
      'icon': 'fa-credit-card',
      'roles': ['admin']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "movements" */ '../views/movements/list.vue')
  },
  {
    path: '/movements/edit/:id(\\d+)',
    name: 'movements.edit',
    meta: {
      'roles': ['admin']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "movements" */ '../views/movements/edit.vue')
  },
  {
    path: '/movements/new',
    name: 'movements.new',
    meta: {
      'roles': ['admin']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "movements" */ '../views/movements/new.vue')
  },
  {
    path: '/movements/move',
    name: 'movements.move',
    meta: {
      'roles': ['admin']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "movements" */ '../views/movements/move.vue')
  },
  {
    path: '/wallets',
    name: 'wallets',
    meta: {
      'label': 'Wallets',
      'icon': 'fa-wallet',
      'roles': ['admin']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "wallets" */ '../views/wallets/list.vue')
  },
  {
    path: '/wallets/edit/:id(\\d+)',
    name: 'wallets.edit',
    meta: {
      'roles': ['admin']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "wallets" */ '../views/wallets/edit.vue')
  },
  {
    path: '/wallets/new',
    name: 'wallets.new',
    meta: {
      'roles': ['admin']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "wallets" */ '../views/wallets/new.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      'roles': []
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/statistics',
    name: 'statistics',
    meta: {
      'label': 'Statistiche',
      'icon': 'fa-chart-line',
      'roles': ['admin', 'user']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "statistics" */ '../views/statistics/view.vue')
  },
  {
    path: '/users',
    name: 'users',
    meta: {
      'label': 'Utenti',
      'icon': 'fa-user',
      'roles': ['admin']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "users" */ '../views/users/list.vue')
  },
  {
    path: '/users/edit/:id(\\d+)',
    name: 'users.edit',
    meta: {
      'roles': ['admin']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "users" */ '../views/users/edit.vue')
  },
  {
    path: '/users/new',
    name: 'users.new',
    meta: {
      'roles': ['admin']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "users" */ '../views/users/new.vue')
  },
  {
    path: '/events/new',
    name: 'events.new',
    meta: {
      'label': 'Nuovo Evento',
      'icon': 'fa-calendar',
      'roles': ['admin']
    },
    // dichiarando il componente in questo modo, viene creato un chunk caricato solo quando si visita la pagina
    component: () => import(/* webpackChunkName: "events" */ '../views/events/new.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: "active",
})
router.beforeEach(async (to) => {
  const publicPages = ['login'];
  const authRequired = !publicPages.includes(to.name);
  const token = store.getters.token;

  if (authRequired && !token) {
    return '/login';
  }

  if (to?.meta?.roles === undefined || token && !to.meta.roles.includes(token.role)) {
    return '/';
  }
});

export default router

export function hasHistory() {
  return window.history.length > 2
}
