<script setup>
import Events from "@/components/home/Events.vue";
import Stats from "@/components/home/Stats.vue";
import {onMounted} from "vue";
import process from "process";

onMounted(() => {
  document.title = process.env.VUE_APP_APP_NAME
})
</script>
<template>
  <Stats/>
  <Events/>
</template>