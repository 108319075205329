import request from '@/utils/request'
export function getList(data = null) {
    return request({
        url: '/api/v1/movements',
        method: 'post',
        data
    })
}

export function getItem(id) {
    return request({
        url: '/api/v1/movements/' + id,
        method: 'get'
    })
}

export function create(item) {
    return request({
        url: '/api/v1/movements/new',
        method: 'post',
        data: item
    })
}

export function move(from, to, item) {
    return request({
        url: '/api/v1/movements/move/' + from + '/' + to,
        method: 'post',
        data: item
    })
}

export function update(item) {
    return request({
        url: '/api/v1/movements/' + item.movement.id,
        method: 'put',
        data: item
    })
}


export function remove(item) {
    return request({
        url: '/api/v1/movements/' + item.id,
        method: 'delete'
    })
}