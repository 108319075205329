import {getFullList, getList} from "@/api/quickStats";

const state = {
    stats: {},
    full: {},
    lastUpdate: null,
}

const mutations = {
    SET_STATS(state, value) {
        state.stats = value
        state.lastUpdate = new Date()
    },
    SET_FULL(state, value) {
        state.full = value
    },
}

const actions = {
    get({commit}) {
        return new Promise((resolve, reject) => {
            getList().then(response => {
                try {
                    commit('SET_STATS', response['statistic'])
                    resolve()
                } catch (e){
                    reject(e)
                }
            }).catch(error => {
                reject(error)
            })
        })
    },
    getFull({commit}) {
        return new Promise((resolve, reject) => {
            getFullList().then(response => {
                try {
                    commit('SET_FULL', response['statistic'])
                    resolve()
                } catch (e){
                    reject(e)
                }
            }).catch(error => {
                reject(error)
            })
        })
    },
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}