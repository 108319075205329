export const eventType = {
    GIG: 0,
    REHEARSAL: 1
}

export const status = {
    CONFIRMED: 0,
    NOT_CONFIRMED: 1
}

export const availabilityStatus = {
    PRESENT: 0,
    ABSENT: 1,
    TO_CHECK: 2
}