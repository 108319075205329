<script setup>
  import {computed} from "vue";
  import process from "process";

  const env = computed(() => process.env);
</script>
<template>
  <footer class="py-3 footer">
    <div class="container-fluid">
      <div class="row align-items-center justify-content-lg-between">
        <div class="mb-4">
          <div class="text-sm text-center copyright text-muted text-lg-start">
            © 2024 realizzato da Band Planner - Versione {{env.VUE_APP_VERSION}}
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
